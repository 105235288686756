.wiki-container {
    max-width: 90%;
    margin: auto;
    text-align: center;
    font-family: Arial, sans-serif;
}

.wiki-title {
    font-size: 28px;
    color: #333;
    margin-bottom: 15px;
}

.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 10px;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    border: 2px solid #ccc;
    border-radius: 5px;
    background: white;
    font-weight: bold;
    transition: all 0.3s ease;
}

.tab:hover {
    background: #f1f1f1;
}

.tab.active {
    background: #c4cdd5;
    color: #212b36;
}

.tab-content {
    display: none;
    padding: 15px;
    border: 1px solid #ccc;
    background: #fff;
}

.tab-content.active {
    display: block;
}

.table-container {
    width: 100%;
    overflow-x: auto;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
}

.table-body {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
}

.table-header, .table-row {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px
}

.table-header {
    background: #c4cdd5;
    color: #212b36;
    font-size: 14px
}

.table-col:hover {
    background: #f1f1f1;
}

.no-data {
    text-align: center;
    font-style: italic;
    color: gray;
}